import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { Button, Stack } from "@mui/material";
import SupportModal from "./support-modal";
import PortalPopup from "./portal-popup";
import styled, { keyframes } from "styled-components";

const Continlogo2a1Icon = styled.img`
  width: 61.144rem;
  position: absolute;
  margin: 0 !important;
  top: -8.956rem;
  left: -11.506rem;
  height: 56.294rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 0;
`;
const animationSlideInLeft = keyframes`
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `;
const BuildBeautifulApps = styled.div`
  align-self: stretch;
  position: relative;
  // letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const Always = styled.p`
  margin: 0;
`;
const SimpleAndCleanContainer = styled.b`
  align-self: stretch;
  position: relative;
  font-size: var(--h1a-size);
  line-height: 120%;
  font-family: var(--h2a);
  letter-spacing: normal;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const PowerfulYetCleanContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--h3-size);
  line-height: 1.875rem;
  @media screen and (max-width: 960px) {
    text-align: center;
  }
`;
const BuildBeautifulAppsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: flex-start;
  }
`;
const Button1 = styled.div`
  position: relative;
  font-size: var(--btns-size);
  line-height: 120%;
  font-family: var(--btns);
  color: var(--primary-main);
  text-align: center;
  cursor: pointer;
`;
const Button2 = styled.button`
  cursor: pointer;
  border: 1px solid var(--primary-main);
  padding: var(--padding-sm) var(--padding-9xl);
  background-color: transparent;
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--color-gainsboro-100);
  }
`;
const Button3 = styled(Button)`
  cursor: pointer;
  &:hover {
    background-color: var(--color-royalblue-100);
  }
`;
// const ButtonGroup1 = styled(ButtonGroup)`
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   justify-content: flex-start;
//   gap: var(--gap-5xs);
//   @media screen and (max-width: 960px) {
//     align-items: center;
//     justify-content: flex-start;
//   }
// `;
const HeroText = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: flex-start;
  }
`;
const HeroContent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 0rem 0rem var(--padding-3xs);
  opacity: 0;
  z-index: 1;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInLeft};
  }
  @media screen and (max-width: 960px) {
    align-items: center;
    justify-content: center;
    flex: unset;
    align-self: stretch;
  }
`;
const animationSlideInTop = keyframes`
    0% {
        transform: translateY(-200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  `;
const HeroSectionsChild = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  opacity: 0;
  z-index: 2;
  &.animate {
    animation: 0.5s ease 0s 1 normal forwards ${animationSlideInTop};
  }
  @media screen and (max-width: 960px) {
    order: -1;
    flex: unset;
    align-self: stretch;
    overflow: revert;
  }
`;
const HeroSectionsRoot = styled.section`
  align-self: stretch;
  background-color: var(--dominant);
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-81xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-41xl);
  min-height: 40rem;
  text-align: left;
  font-size: var(--p1-size);
  color: var(--dark-gray);
  font-family: var(--h3);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    padding: var(--padding-31xl);
    box-sizing: border-box;
  }
`;

const HeroSections = () => {
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const openSupportModal = useCallback(() => {
    setSupportModalOpen(true);
  }, []);

  const closeSupportModal = useCallback(() => {
    setSupportModalOpen(false);
  }, []);

  const onButton1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='feature1Section']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <HeroSectionsRoot>
        <Continlogo2a1Icon alt="" src="/continlogo2a-11@2x.png" />
        <HeroContent data-animate-on-scroll>
          <HeroText>
            <BuildBeautifulAppsParent>
              <BuildBeautifulApps>
                Achieve Seamless Adherence to the ASD Essential Eight Guidelines
              </BuildBeautifulApps>
              <SimpleAndCleanContainer>
                <Always>Always </Always>
                <Always>Assessment Ready! </Always>
              </SimpleAndCleanContainer>
              <PowerfulYetCleanContainer>
                <Always>
                  Achieve your Cybersecurity Compliance with Continuous
                  Assurance provided by ALEiGN.
                </Always>
              </PowerfulYetCleanContainer>
            </BuildBeautifulAppsParent>
            <Stack direction="row" spacing={2}>
              <Button
                variant='outlined'
                color='secondary'
                // onClick={openSupportModal}
                onClick={() => window.FreshworksWidget('open')}
                >
                Request a Demo
              </Button>
              <Button
                disableElevation={true}
                color="primary"
                variant="contained"
                onClick={onButton1Click}
              >
                Learn More
              </Button>
            </Stack>
          </HeroText>
        </HeroContent>
        <HeroSectionsChild
          alt=""
          src="/rectangle-1@2x.png"
          data-animate-on-scroll
        />
      </HeroSectionsRoot>
      {isSupportModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSupportModal}
        >
          <SupportModal onClose={closeSupportModal} />
        </PortalPopup>
      )}
    </>
  );
};

export default HeroSections;
