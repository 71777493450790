import * as React from "react";
import styled from "styled-components";

const SupportModalIcon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const SupportModalRoot = styled.div`
  width: 27.813rem;
  position: relative;
  height: 44.688rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

const SupportModal = () => {
  return (
    <SupportModalRoot>
      <SupportModalIcon alt="" src="/support-modal@2x.png" />
    </SupportModalRoot>
  );
};

export default SupportModal;
