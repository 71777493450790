import * as React from "react";
import styled from "styled-components";
import Feature2Card from "./feature2-card";

const Source = styled.source``;
const BackgroundVideo = styled.video`
  // height: 111.36%;
  // width: 126.53%;
  position: absolute;
  min-width: 100%; 
  min-height: 100%;
  object-fit: fill;
  // margin: 0 !important;
  // top: -3.32%;
  right: 0;
  bottom: 0;
  // left: -18.96%;
  mix-blend-mode: normal;
  z-index: 0;
  @media screen and (max-width: 1200px) {
    flex: 1;
    align-self: stretch;
    height: auto;
    width: 50% !important;
  }
  @media screen and (max-width: 960px) {
    width: 50% !important;
    height: auto !important;
  }
`;
const WhyChooseContinuous = styled.b`
  position: relative;
  line-height: 120%;
`;
const CalloutTitle = styled.div`
  background-color: var(--dominant);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const AsOneOf = styled.p`
  margin-block-start: 0;
  margin-block-end: 18px;
`;
const TheAdvantageIs = styled.p`
  margin: 0;
`;
const CalloutText = styled.div`
  width: 51.875rem;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  font-family: var(--h3);
  color: var(--color-darkslategray-100);
  display: none;
`;
const CalloutCopywriting = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0rem var(--padding-17xl);
  gap: var(--gap-11xl);
  z-index: 1;
  @media screen and (max-width: 960px) {
    width: 100%;
    padding-left: 0rem;
    padding-top: var(--padding-21xl);
    padding-bottom: var(--padding-8xs);
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
`;
const CalloutSwissWinterImage = styled.div`
  align-self: stretch;
  flex: 1;
  position: relative;
  mix-blend-mode: linear-dodge;
  @media screen and (max-width: 1200px) {
    width: 50% !important;
  }
  @media screen and (max-width: 960px) {
    width: 50% !important;
    height: auto !important;
  }
`;
const CalloutImages = styled.div`
  align-self: stretch;
  width: 19.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    width: 96% !important;
  }
`;
const Feature2SectionRoot = styled.section`
  align-self: stretch;
  background-color: var(--dominant);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-61xl) var(--padding-101xl);
  position: relative;
  gap: var(--gap-41xl);
  text-align: left;
  font-size: var(--h2a-size);
  color: var(--primary-main);
  font-family: var(--h2a);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

const Feature2Section = () => {
  return (
    <Feature2SectionRoot>
      <BackgroundVideo autoPlay muted loop>
        <Source src="/Traffic1080.mp4" />
      </BackgroundVideo>
      <CalloutCopywriting>
        <CalloutTitle>
          <WhyChooseContinuous>
            Why Choose Continuous Compliance?
          </WhyChooseContinuous>
        </CalloutTitle>
        <CalloutText>
          <AsOneOf>{`As one of the alpine countries, landlocked Switzerland with her mountains has to compete not only with her neighbours but with other destinations. There are, e.g., no coastal resorts. `}</AsOneOf>
          <TheAdvantageIs>
            The advantage is that tourism in Switzerland benefits of a large
            diversity of beautiful landscapes in a relatively small space.
          </TheAdvantageIs>
        </CalloutText>
        <Feature2Card
          frame7="/frame-7@2x.png"
          developedByIndustrySpecia="Developed by Industry Specialists"
          ourTeamHasDecadesOfExperi="Our team has decades of experience working with and in, leading corporations and government bodies, with a proven track record of delivering excellence."
        />
        <Feature2Card
          frame7="/frame-71@2x.png"
          developedByIndustrySpecia="Customisable for Your Needs"
          ourTeamHasDecadesOfExperi="Tailor the platform to suit the unique requirements of your organisation. Our flexible architecture adapts to your evolving compliance landscape."
        />
        <Feature2Card
          frame7="/frame-72@2x.png"
          developedByIndustrySpecia="Tailored Support"
          ourTeamHasDecadesOfExperi="We are committed to your success in achieving and maintaining compliance. Our support capability evolves to reflect the needs of our clients."
        />
      </CalloutCopywriting>
      <CalloutImages>
        <CalloutSwissWinterImage />
      </CalloutImages>
    </Feature2SectionRoot>
  );
};

export default Feature2Section;
