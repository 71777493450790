import * as React from "react";
import { useEffect, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import { Button, Stack } from "@mui/material";

const animationSlideInLeft = keyframes`
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `;
const CardChild = styled.img`
  align-self: stretch;
  flex: 1;
  max-width: 16.25rem;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  min-width: 12.5rem;
`;
const DevelopedByIndustry = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const DevelopedByIndustrySpecialiWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const OurTeamHas = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  font-family: var(--h3);
  color: var(--gray);
`;
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Button1 = styled.div`
  position: relative;
  font-size: var(--btns-size);
  line-height: 120%;
  font-family: var(--btns);
  color: var(--dominant);
  text-align: center;
`;
const Button2 = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-5xs) var(--padding-xl);
  background-color: var(--primary-main);
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--color-royalblue-100);
  }
`;
const FrameParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-lg);
  min-width: 18.75rem;
`;
const CardRoot = styled.div`
  align-self: stretch;
  background-color: var(--dominant);
  border-bottom: 8px solid var(--dark-gray);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  text-align: left;
  font-size: var(--h3a-size);
  color: var(--dark-gray);
  font-family: var(--h2a);
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInLeft};
  }
`;

const Feature2Card = ({
  frame7,
  developedByIndustrySpecia,
  ourTeamHasDecadesOfExperi,
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onButton1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='feature1Section']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);


  return (
    <CardRoot data-animate-on-scroll>
      <CardChild alt="" src={frame7} />
      <FrameParent>
        <FrameGroup>
          <DevelopedByIndustrySpecialiWrapper>
            <DevelopedByIndustry>
              {developedByIndustrySpecia}
            </DevelopedByIndustry>
          </DevelopedByIndustrySpecialiWrapper>
          <OurTeamHas>{ourTeamHasDecadesOfExperi}</OurTeamHas>
        </FrameGroup>
        <Stack direction="row" spacing={2}>
          {/* <Button
            variant='outlined'
            color='secondary'
            // onClick={openSupportModal}
            onClick={() => window.FreshworksWidget('open')}
          >
            Request a Demo
          </Button> */}
          {/* <Button
            disableElevation={true}
            color="secondary"
            variant="contained"
            onClick={onButton1Click}
          >
            Learn More
          </Button> */}
        </Stack>
      </FrameParent>
    </CardRoot>
  );
};

export default Feature2Card;
