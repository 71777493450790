import * as React from "react";
import Navbar from "../components/navbar";
import HeroSections from "../components/hero-sections";
import FeatureSection from "../components/feature-section";
import Video1Section from "../components/video1-section";
import Feature2Section from "../components/feature2-section";
import styled from "styled-components";
import Content1Section from "../components/content1-section";
import Footer from "../components/footer";

const TrustedByThe = styled.div`
  align-self: stretch;
  position: relative;
`;
const BrandChild = styled.img`
  width: 8.881rem;
  position: relative;
  height: 0.188rem;
`;
const Compliancelogos1Icon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  mix-blend-mode: darken;
`;
const Compliancelogos1Wrapper = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const FrameworkLogosChild = styled.img`
  width: 30rem;
  position: relative;
  height: 6.063rem;
  object-fit: cover;
`;
const FrameworkLogos = styled.div`
  width: 60.875rem;
  height: 6.063rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm);
`;
const Brand = styled.div`
  width: 90rem;
  background-color: var(--primary-main);
  height: 19.688rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-31xl) var(--padding-238xl);
  box-sizing: border-box;
`;
const HomeRoot = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-2xl);
  color: var(--dominant);
  font-family: var(--text-tiny-link);
  letter-spacing: normal;
`;

const Home = () => {
  return (
    <HomeRoot>
      <Navbar
        continLogo2a1="/continlogo2a-1.svg"
        accountSection={false}
        hamburgerMenu="/notification.svg"
        showHamburgerMenuIcon
        notificationBell="/notification1.svg"
        profilePicture="/top_avatar@2x.png"
        actionButton="Get in Touch"
        iconChevronRight="/icon--chevronright@2x.png"
        showActionButton={false}
        showActionButtonLink={false}
      />
      <HeroSections />
      <FeatureSection />
      <Video1Section />
      <Feature2Section />
      <Content1Section />
      <Footer />
    </HomeRoot>
  );
};

export default Home;
