import * as React from "react";
import { useState, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import SupportModal from "./support-modal";
import PortalPopup from "./portal-popup";
import styled from "styled-components";
import { Link } from 'gatsby'
import MUILink from '@mui/material/Link';

const Source = styled.source``;
const Video = styled.video`
  flex: 1;
  border-radius: var(--br-5xs);
  height: 22.375rem;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const WhatIsContinuous = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const ContinuousComplianceRefers = styled.p`
  margin-block-start: 0;
  margin-block-end: 18px;
`;
const TheAleignContinuous = styled.p`
  margin: 0;
`;
const ContinuousComplianceRefersContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  font-family: var(--h3);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xl);
`;
const Button1 = styled.div`
  position: relative;
  font-size: var(--btns-size);
  line-height: 120%;
  font-family: var(--btns);
  color: var(--primary-main);
  text-align: center;
  cursor: pointer;
`;
const Button2 = styled.button`
  cursor: pointer;
  border: 1px solid var(--primary-main);
  padding: var(--padding-sm) var(--padding-9xl);
  background-color: transparent;
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--color-gainsboro-100);
  }
`;
const Button3 = styled.div`
  position: relative;
  font-size: var(--btns-size);
  line-height: 120%;
  font-family: var(--btns);
  color: var(--dominant);
  text-align: center;
`;
const Button4 = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-sm) var(--padding-9xl);
  background-color: var(--primary-main);
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--color-royalblue-100);
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Video1Content = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: var(--gap-xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Video1SectionRoot = styled.section`
  align-self: stretch;
  background-color: var(--dominant);
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-71xl) var(--padding-101xl);
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--h2a-size);
  color: var(--dark-gray);
  font-family: var(--h2a);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

const Video1Section = () => {
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);

  const openSupportModal = useCallback(() => {
    setSupportModalOpen(true);
  }, []);

  const closeSupportModal = useCallback(() => {
    setSupportModalOpen(false);
  }, []);

  const onButton1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='feature1Section']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <Video1SectionRoot>
        {/* <Video controls>
          <Source src="/e8_walk thru.mp4" />
        </Video> */}
        <Video1Content>
          <Content>
            <WhatIsContinuous>What is Continuous Compliance?</WhatIsContinuous>
            <ContinuousComplianceRefersContainer>
              <ContinuousComplianceRefers>{`Continuous compliance refers to an approach in which an organisation consistently and proactively adheres to regulatory requirements, security standards, and internal policies in an ongoing and automated manner. `}</ContinuousComplianceRefers>
              <ContinuousComplianceRefers>
                Traditional compliance processes often involve periodic audits
                or assessments, but continuous compliance emphasises real-time
                monitoring, automated checks, and immediate remediation of
                non-compliance issues. This approach is particularly relevant in
                the context of information security, data protection, and other
                regulatory frameworks.
              </ContinuousComplianceRefers>
              <TheAleignContinuous>
                The ALEiGN Continuous Compliance Methodology will be tailored to
                your specific threats and risks. This mean you can align your
                resources in the best possible way.
              </TheAleignContinuous>
            </ContinuousComplianceRefersContainer>
          </Content>
          <Stack 
            direction="row" 
            spacing={2} 
            justifyContent="flex-end"
          >
            <Button
              variant='outlined'
              color='secondary'
              // onClick={openSupportModal}
              onClick={() => window.FreshworksWidget('open')}
            >
              Request a Demo
            </Button>
            <Button
              disableElevation={true}
              color="primary"
              variant="contained"
              onClick={onButton1Click}
            >
              Learn More
            </Button>
          </Stack>
        </Video1Content>
      </Video1SectionRoot>
      {isSupportModalOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSupportModal}
        >
          <SupportModal onClose={closeSupportModal} />
        </PortalPopup>
      )}
    </>
  );
};

export default Video1Section;
