import * as React from "react";
import { Button, Icon } from "@mui/material";
import styled from "styled-components";
import { Link } from 'gatsby';


const Continlogo2a1Icon = styled.img`
  width: 4.875rem;
  position: relative;
  height: 2.375rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const LogoIcon = styled.img`
  align-self: stretch;
  // width: 100px;
  // max-height: 100%;
  object-fit: cover;
`;
const Logocontainer = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 3.125rem;
`;
const LinkOne = styled(Button)`
  position: relative;
  color: var(--text-alternate);
`;
const NavLinkDropdown = styled(Button)``;
const Navlinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const Column = styled.div`
  height: 5.063rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Subscribe = styled.div`
  position: relative;
  line-height: 150%;
  font-weight: 600;
`;
const ActionButton = styled(Button)`
  align-self: stretch;
`;
const BySubscribingYou = styled.div`
  position: relative;
  line-height: 150%;
`;
const PrivacyPolicy = styled.div`
  position: relative;
  text-decoration: underline;
  line-height: 150%;
  text-transform: uppercase;
  font-family: var(--text-tiny-link);
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  text-align: left;
  font-size: var(--text-tiny-link-size);
`;
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 0rem var(--padding-xl);
  gap: var(--gap-7xs);
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;
const Divider = styled.div`align-self: stretch;
  position: relative;
  background-color: var(--text-alternate);
  border: 1px solid var(--text-alternate);
  box-sizing: border-box;
  height: 0.063rem;
  background-color: ${(p) => p.dividerBackgroundColor}
  border: ${(p) => p.dividerBorder}
`;
const TermsOfService = styled(Link)`
  position: relative;
  text-decoration: underline;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--text-alternate);
`;
const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const AleignAllRights = styled.div`
  position: relative;
  font-size: var(--p2-size);
  line-height: 150%;
  font-family: var(--text-small-normal);
`;
const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
`;
const Credits = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--text-tiny-link-size);
  font-family: var(--text-tiny-link);
`;
const FooterRoot = styled.footer`align-self: stretch;
  background-color: var(--primary-main);
  border-bottom: 1px solid var(--text-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-41xl) var(--padding-45xl);
  text-align: center;
  font-size: var(--btns-size);
  color: var(--text-alternate);
  font-family: var(--text-small-normal);
  align-self: ${(p) => p.footer8AlignSelf}
  padding: ${(p) => p.footer8Padding}
  width: ${(p) => p.footer8Width}
  height: ${(p) => p.footer8Height}
`;

const Footer = ({
  footer8AlignSelf,
  footer8Padding,
  footer8Width,
  footer8Height,
  dividerBackgroundColor,
  dividerBorder,
}) => {
  return (
    <FooterRoot
      footer8AlignSelf={footer8AlignSelf}
      footer8Padding={footer8Padding}
      footer8Width={footer8Width}
      footer8Height={footer8Height}
    >
      <Content>
        <Column>
          <Logocontainer>
              <LogoIcon alt="" src="/logo@2x.png" />
              <Continlogo2a1Icon alt="" src="/continlogo2a-1.svg" />
            </Logocontainer>
          <Navlinks>
            
            {/* <LinkOne
              disableElevation={true}
              color="primary"
              variant="text"

            >
              Solutions
            </LinkOne> */}
            <LinkOne
              disableElevation={true}
              variant="text"
              href='/e8Guide'
            >
              Resources
            </LinkOne>
            <LinkOne
              disableElevation={true}
              variant="text"
              target="_blank"
              href='https://www.aleign.com/#AboutV2'
            >
              About Us
            </LinkOne>
            {/* <NavLinkDropdown
              disableElevation={true}
              color="primary"
              variant="text"
              endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
              
            >
              Resources
            </NavLinkDropdown> */}
          </Navlinks>
        </Column>
        <Actions>
          <Subscribe>Do you have a question?</Subscribe>
          {/* <ActionButton
            disableElevation={true}
            color="primary"
            variant="contained"
            // sx={{ borderRadius: "0px 0px 0px 0px" }}
          >
            Get in Touch
          </ActionButton> */}
          <Button
                variant='outlined'
                color='primary'
                // onClick={openSupportModal}
                onClick={() => window.FreshworksWidget('open')}
                >
                Get in Touch
              </Button>
          {/* <Container>
            <BySubscribingYou>
              By subscribing, you agree to our Privacy Policy
            </BySubscribingYou>
            <PrivacyPolicy>Privacy Policy</PrivacyPolicy>
          </Container> */}
        </Actions>
      </Content>
      <Credits>
        <Divider
          dividerBackgroundColor={dividerBackgroundColor}
          dividerBorder={dividerBorder}
        />
        <Row>
          <FooterLinks>
            <TermsOfService target="_blank" to='https://www.aleign.com/privacy'>Privacy Policy</TermsOfService>
            <TermsOfService target="_blank" to='https://www.aleign.com/terms-conditions'>Terms and Condition</TermsOfService>
            {/* <TermsOfService>Cookies Settings</TermsOfService> */}
          </FooterLinks>
          <AleignAllRights>© 2024 ALEiGN. All rights reserved.</AleignAllRights>
        </Row>
      </Credits>
    </FooterRoot>
  );
};

export default Footer;
