import * as React from "react";
import { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const UnrivalledCybersecurityCompl = styled.b`
  position: relative;
  line-height: 120%;
`;
const InTheDynamic = styled.p`
  margin: 0;
`;
const InTheDynamicContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-2xl);
  letter-spacing: 0.05em;
  line-height: 2.188rem;
  font-weight: 300;
  font-family: var(--h3);
`;
const Feature1Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem var(--padding-49xl);
  gap: var(--gap-lgi);
`;
const animationSlideInBottom = keyframes`
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }    
  `;
const Clock1Icon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const Icons = styled.div`
  width: 6.875rem;
  height: 6.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const UserFriendly = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const TheBigOxmox = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  font-family: var(--h3);
  color: var(--gray);
  white-space: pre-wrap;
`;
const UserFriendlyParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Button1 = styled.div`
  position: relative;
  font-size: var(--btns-size);
  line-height: 1.5rem;
  font-family: var(--text-medium);
  color: var(--studio-darkmode-maincta-457eff);
  text-align: center;
`;
const Icon1 = styled.img`
  width: 1.125rem;
  position: relative;
  height: 1.125rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const Button2 = styled.button`
  cursor: pointer;
  border: 1px solid var(--studio-darkmode-maincta-457eff);
  padding: var(--padding-sm) var(--padding-9xl);
  background-color: transparent;
  border-radius: var(--br-7xs);
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  &:hover {
    background-color: var(--color-gainsboro-100);
  }
`;
const Card = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--dominant);
  border-top: 10px solid var(--dark-gray);
  border-bottom: 10px solid var(--dark-gray);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-base) var(--padding-41xl);
  gap: var(--gap-lg);
  opacity: 0;
  min-width: 18.75rem;
  &.animate {
    animation: 0.5s ease 0s 1 normal forwards ${animationSlideInBottom};
  }
`;
const OurPlatformFlags = styled.p`
  margin-block-start: 0;
  margin-block-end: 18px;
`;
const TheBigOxmoxContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  font-family: var(--h3);
  color: var(--gray);
`;
const Card1 = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: var(--dominant);
  border-top: 10px solid var(--dark-gray);
  border-bottom: 10px solid var(--dark-gray);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-base) var(--padding-41xl);
  gap: var(--gap-lg);
  opacity: 0;
  min-width: 18.75rem;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInBottom};
  }
`;
const Icons1 = styled.div`
  width: 6.875rem;
  height: 6.875rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Features = styled.div`
  align-self: stretch;
  height: 53.563rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-10xl);
  font-size: var(--h3a-size);
`;
const Feature1SectionRoot = styled.section`
  align-self: stretch;
  background-color: var(--dominant);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-81xl) var(--padding-81xl) var(--padding-131xl);
  gap: var(--gap-21xl);
  text-align: center;
  font-size: var(--h2a-size);
  color: var(--dark-gray);
  font-family: var(--h2a);
`;

const FeatureSection = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <Feature1SectionRoot data-scroll-to="feature1Section">
      <Feature1Title>
        <UnrivalledCybersecurityCompl>
          Unrivalled Cybersecurity Compliance
        </UnrivalledCybersecurityCompl>
        <InTheDynamicContainer>
          <InTheDynamic>In the dynamic landscape of cybersecurity, maintaining compliance with industry standards is paramount. </InTheDynamic>
          <InTheDynamic>
            ALEiGN’s compliance methodologies are your trusted allies, offering
            a cutting-edge Continuous Compliance solution tailored for large
            corporations and government entities seeking to align with the
            stringent ASD Essential Eight guidelines.
          </InTheDynamic>
        </InTheDynamicContainer>
      </Feature1Title>
      <Features>
        <Card data-animate-on-scroll>
          <Icons>
            <Clock1Icon alt="" src="/clock-1.svg" />
          </Icons>
          <UserFriendlyParent>
            <UserFriendly>Compliance Monitoring</UserFriendly>
            <TheBigOxmox>The ALEiGN Compliance Methodology can monitor for changes to the Essential Eight that affect your organisation.  </TheBigOxmox>
          </UserFriendlyParent>
          <Button2>
            <Button1>Read more</Button1>
            <Icon1 alt="" src="/icon.svg" />
          </Button2>
        </Card>
        <Card1 data-animate-on-scroll>
          <Icons>
            <Clock1Icon alt="" src="/settings-1.svg" />
          </Icons>
          <UserFriendlyParent>
            <UserFriendly>Automated Remediation Workflows</UserFriendly>
            <TheBigOxmoxContainer>
              <OurPlatformFlags>Our platform flags corrective actions automatically, ensuring that your team is always aware of your systems and process alignment with the Essential Eight guidelines.</OurPlatformFlags>
            </TheBigOxmoxContainer>
          </UserFriendlyParent>
          <Button2>
            <Button1>Read more</Button1>
            <Icon1 alt="" src="/icon.svg" />
          </Button2>
        </Card1>
        <Card1 data-animate-on-scroll>
          <Icons>
            <Clock1Icon alt="" src="/task-1.svg" />
          </Icons>
          <UserFriendlyParent>
            <UserFriendly>Integrated Task Management</UserFriendly>
            <TheBigOxmoxContainer>
              Seamlessly manage tasks related to compliance, effortlessly
              assigning responsibilities and tracking progress. Streamline your
              workflow and enhance collaboration within your organisation.
            </TheBigOxmoxContainer>
          </UserFriendlyParent>
          <Button2>
            <Button1>Read more</Button1>
            <Icon1 alt="" src="/icon.svg" />
          </Button2>
        </Card1>
        <Card1 data-animate-on-scroll>
          <Icons>
            <Clock1Icon alt="" src="/coffee-1.svg" />
          </Icons>
          <UserFriendlyParent>
            <UserFriendly>Cultural Compliance Integration</UserFriendly>
            <TheBigOxmoxContainer>
              Foster a culture of compliance within your organisation. Our
              platform ensures your people understand the context of compliance
              within your organisation, making compliance a culture and a shared
              responsibility.
            </TheBigOxmoxContainer>
          </UserFriendlyParent>
          <Button2>
            <Button1>Read more</Button1>
            <Icon1 alt="" src="/icon.svg" />
          </Button2>
        </Card1>
        <Card1 data-animate-on-scroll>
          <Icons1>
            <Clock1Icon alt="" src="/target-1.svg" />
          </Icons1>
          <UserFriendlyParent>
            <UserFriendly>Change Management Excellence</UserFriendly>
            <TheBigOxmoxContainer>
              <OurPlatformFlags>Navigate changes with confidence. <br/>Facilitate a robust change management process, ensuring that
                modifications align with compliance standards. </OurPlatformFlags>
             
            </TheBigOxmoxContainer>
          </UserFriendlyParent>
          <Button2>
            <Button1>Read more</Button1>
            <Icon1 alt="" src="/icon.svg" />
          </Button2>
        </Card1>
      </Features>
    </Feature1SectionRoot>
  );
};

export default FeatureSection;
