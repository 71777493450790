import * as React from "react";
import { useCallback } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";

const CatchUpTo = styled.b`
  align-self: stretch;
  position: relative;
  letter-spacing: 0.06em;
  line-height: 2.63rem;
  font-family: var(--h3);
  font-size: var(--h3a-size);
`;
const RecentStoryEntries = styled.b`
  align-self: stretch;
  position: relative;
  font-size: var(--h2a-size);
  line-height: 120%;
  font-family: var(--h2a);
  color: var(--color-black);
`;
const Content1Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
`;
const ZermattCardImage = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 5.26px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 420px) {
    width: 100% !important;
    height: auto !important;
  }
`;
const NavigatingTheEssential = styled.p`
  margin: 0;
`;
const ZermattCardTitleContainer = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const InTheEverEvolving = styled.span``;
const ViewFullBlog = styled.span`
  color: var(--studio-darkmode-maincta-457eff);
`;
const ZermattCardDescriptionContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--p1-size);
  letter-spacing: 0.04em;
  line-height: 1.813rem;
  font-weight: 300;
  font-family: var(--h3);
  color: var(--color-slategray-100);
`;
const ZermattCardDetails = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const HeroCard = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: 10.52px;
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xl);
  gap: var(--gap-2xl);
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    width: 100%;
    flex: unset;
    align-self: stretch;
  }
`;
const TuscanyCardImage = styled.img`
  align-self: stretch;
  width: 14.063rem;
  position: relative;
  border-radius: 5.26px;
  max-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
const TuscanyCardDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const SubheroCard = styled.div`
  align-self: stretch;
  border-radius: 10.52px;
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-3xl);
  gap: var(--gap-5xl);
  cursor: pointer;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: 1.875rem 1.5rem;
  }
`;
const KyotoCardImage = styled.img`
  align-self: stretch;
  width: 14rem;
  position: relative;
  border-radius: 5.26px;
  max-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
const EmbarkingOnThe = styled.span`
  white-space: pre-wrap;
`;
const SmallCards = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  @media screen and (max-width: 1200px) {
    flex: unset;
    align-self: stretch;
  }
`;
const CardsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xl);
  text-align: left;
  font-size: var(--h3a-size);
  color: var(--color-darkslategray-100);
  font-family: var(--h2a);
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;
const Content1SectionRoot = styled.section`
  align-self: stretch;
  background-color: var(--dominant);
  box-shadow: 0px 0px 52.6px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-31xl) var(--padding-78xl);
  gap: var(--gap-27xl);
  text-align: center;
  font-size: var(--font-size-7xl-3);
  color: var(--color-crimson);
  font-family: var(--text-small-normal);
  @media screen and (max-width: 1200px) {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
`;

const Content1Section = () => {
  const onHeroCardContainerClick = useCallback(() => {
    navigate("/e8Guide");
  }, []);

  const onSubHeroCardContainerClick = useCallback(() => {
    navigate("/culture");
  }, []);

  const onSubHeroCardContainer1Click = useCallback(() => {
    navigate("/pitfalls");
  }, []);

  const onSubHeroCardContainer2Click = useCallback(() => {
    navigate("/trends");
  }, []);

  return (
    <Content1SectionRoot>
      <Content1Title>
        <CatchUpTo>Our Thoughts and Contemplations</CatchUpTo>
        <RecentStoryEntries>Active ALEiGNment Journal</RecentStoryEntries>
      </Content1Title>
      <CardsContainer>
        <HeroCard onClick={onHeroCardContainerClick}>
          <ZermattCardImage alt="" src="/zermatt-card-image@2x.png" />
          <ZermattCardDetails>
            <ZermattCardTitleContainer>
              <NavigatingTheEssential>{`Navigating the Essential Eight: `}</NavigatingTheEssential>
              <NavigatingTheEssential>{`A Comprehensive Guide to ASD & ACSC Guidelines`}</NavigatingTheEssential>
            </ZermattCardTitleContainer>
            <ZermattCardDescriptionContainer>
              <InTheEverEvolving>{`In the ever-evolving landscape of cybersecurity, organizations face the critical task of safeguarding their digital assets against an array of threats. `}</InTheEverEvolving>
              <ViewFullBlog>view full blog</ViewFullBlog>
            </ZermattCardDescriptionContainer>
          </ZermattCardDetails>
        </HeroCard>
        <SmallCards>
          <SubheroCard onClick={onSubHeroCardContainerClick}>
            <TuscanyCardImage alt="" src="/tuscany-card-image@2x.png" />
            <TuscanyCardDetails>
              <ZermattCardTitleContainer>
                The Role of Culture in Compliance: Building a Security-Conscious
                Organisation
              </ZermattCardTitleContainer>
              <ZermattCardDescriptionContainer>
                <InTheEverEvolving>{`In the realm of cybersecurity, compliance extends beyond a checklist of technical measures—it's about fostering a culture that champions security at every level of your organization..... `}</InTheEverEvolving>
                <ViewFullBlog>view full blog</ViewFullBlog>
              </ZermattCardDescriptionContainer>
            </TuscanyCardDetails>
          </SubheroCard>
          <SubheroCard onClick={onSubHeroCardContainer1Click}>
            <KyotoCardImage alt="" src="/kyoto-card-image@2x.png" />
            <TuscanyCardDetails>
              <ZermattCardTitleContainer>
                Common Compliance Pitfalls and How to Avoid Them
              </ZermattCardTitleContainer>
              <ZermattCardDescriptionContainer>
                <EmbarkingOnThe>{`Embarking on the path of compliance is a strategic imperative for organizations aiming to fortify their operations and uphold industry standards....  `}</EmbarkingOnThe>
                <ViewFullBlog>view full blog</ViewFullBlog>
              </ZermattCardDescriptionContainer>
            </TuscanyCardDetails>
          </SubheroCard>
          <SubheroCard onClick={onSubHeroCardContainer2Click}>
            <KyotoCardImage alt="" src="/lofoten-card-image@2x.png" />
            <TuscanyCardDetails>
              <ZermattCardTitleContainer>
                The Future of Compliance Technology: Trends to Watch
              </ZermattCardTitleContainer>
              <ZermattCardDescriptionContainer>
                <InTheEverEvolving>
                  In an era defined by rapid technological advancements and an
                  evolving regulatory landscape, the realm of compliance is
                  undergoing a profound transformation ....
                </InTheEverEvolving>
                <ViewFullBlog>view full blog</ViewFullBlog>
              </ZermattCardDescriptionContainer>
            </TuscanyCardDetails>
          </SubheroCard>
        </SmallCards>
      </CardsContainer>
    </Content1SectionRoot>
  );
};

export default Content1Section;
